<script lang="ts" setup>
const img = useImage();
const config = useGlobyConfig();

useHead({
  link: [{ rel: "icon", type: "image/svg+xml", href: "/img/favicon.png" }],
});
</script>

<template>
  <v-app class="pa-0">
    <v-container class="pa-0" fluid>
      <v-row>
        <v-carousel :show-arrows="false" cycle height="200px" hide-delimiters>
          <v-carousel-item
            v-for="imgLoc of config.general.bannerImgs"
            :key="imgLoc"
            :src="img('/header/' + imgLoc)"
            cover
          />
        </v-carousel>
      </v-row>
    </v-container>
    <v-main>
      <router-view />
    </v-main>
    <v-footer
      class="bg-red-darken-1 pa-0"
      padless
      style="border-top: yellow 5px solid"
    >
      <v-container fluid>
        <v-row>
          <v-col align-self="center" class="d-inline-flex" cols="12" sm="4">
            <!--            <v-img :src="img('/img/logo_full.svg')" max-height="4rem"/>-->
            <nuxt-img
              alt="Logo van stichting Sinterklaas Raalte."
              fit="contain"
              src="/img/logo_full.png"
              style="max-height: 7rem"
            />
          </v-col>

          <v-col align-self="center" class="text-center pa-0" cols="12" sm="4">
            Copyright
            <a class="text-black" href="https://globy.app">Globy</a> 2024
          </v-col>
          <v-col align-self="center" class="pa-0 text-center" cols="12" sm="4">
            Mail:
            <a
              :href="'mailto:' + config.organization.email.href"
              class="text-white"
              >{{ config.organization.email.text }}</a
            >
            <br />

            Telefoon:
            <a
              :href="'tel:' + config.organization.telephone.href"
              class="text-white"
              >{{ config.organization.telephone.text }}</a
            >
            <br />
            Privacy:
            <router-link class="text-white" target="_blank" to="/privacy">
              klik hier
            </router-link>
            <br />
            Algemene voorwaarden:
            <router-link class="text-white" target="_blank" to="/voorwaarden">
              klik hier
            </router-link>
            <br />
            KVK nummer: {{ config.organization.kvk_number }}
            <br />
            {{ config.organization.fullName }}
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style scoped></style>
